@import '../variables'

.body--mobile-nav-open
  .page-content
    position: relative
    +tablet
      overflow: hidden
    +mobile
      overflow: hidden

  .page-footer
    +tablet
      display: none
    +mobile
      display: none
