@use "sass:math"

// TODO: remove these imports from this file and keep only variables
@import 'mixins/responsive'
@import 'mixins/topic-link'
@import 'mixins/topic-colors'
@import 'mixins/placeholder'

$blue25: #E6F0FF
$blue500: #036DFF
$blue600: #035DD9
$green25: #ECF6E5
$green300: #2E7400
$grey1000: #191919
$grey700: #404040
$grey500: #707070
$grey300: #949494
$grey100: #CCCCCC
$grey50: #ECECEE
$grey25: #F9F9FB
$orange25: #FFF1E9
$orange500: #FF7122
$orange700: #D74000
$pink25: #FFF2EF
$pink500: #E40046
$pink700: #C62E46
$teal25: #E5F6F6
$teal800: #005B5C
$violet25: #EEECFD
$violet600: #4635C5

$announcementBarHeight: 40px
$base-font-size: 16px
$base-min-font-size: 14px
$base-line-height: 26px
$bodyFont: system-ui, "Roboto", Arial, sans-serif
$bodyTextColor: $grey700
$borderColor: $grey100
$buttonBackgroundColor: #dee4e8
$buttonSecondaryBackgroundColor: $grey100
$buttonTextColor: #4D4D4D
$buyButtonBackgroundColor: #23BA28
$buyButtonTextColor: white
$contentBackgroundColor: $grey25
$coursesBorder: 2px solid #D1D9DE
$coursesBorderColor: #D1D9DE
$coursesInaccessibleColor: #a7b2b9
$coursesPreviewImageRatio: math.div(13, 9)
$coursesPriceBackgroundColor: #1fa756
$coursesSecondaryColor: $grey500
$defaultColor: $grey700
$dropdownMenuLinkHorizontalPadding: 10px
$dropdownMenuWidth: 190px
$ebooksBorder: $coursesBorder
$ebooksBorderColor: $coursesBorderColor
$ebooksInaccessibleColor: $coursesInaccessibleColor
$ebooksPriceBackgroundColor: $coursesPriceBackgroundColor
$errorColor: $orange700
$font-awesome-family: 'Font Awesome 5 Free'
$font-awesome-solid: 900
$font-awesome-regular: 400
$font-awesome-brands-family: 'Font Awesome 5 Brands'
$fa-backward: '\f04a'
$fa-check: '\f00c'
$fa-facebook: '\f09a'
$fa-forward: '\f04e'
$fa-heart: '\f004'
$fa-arrows: '\f047'
$fa-times: '\f00d'
$fa-twitter: '\f099'
$font: system-ui, Arial, sans-serif
// ------- New Style Colours -------
$white: #ffffff
$backgroundGrey: $grey50
$lightestGrey: $grey25
$lightGrey: $grey500
$grey: #999999
$mediumGrey: $grey500
$midGrey: #212a34
$darkGrey: $grey700
$envatoGreen: #82B541
$envatoGreenHover: lighten($envatoGreen, 5%)
$envatoGreenActive: darken($envatoGreen, 5%)
$newBodyTextColor: $grey700
$newSecondaryTextColor: $grey
$newSecondaryBodyTextColour: $grey300
$primaryBlueColor: $blue600
$headingBackgroundColor: #FFF7C9
$headingDividerColor: #E46E4A
$headingFont: $bodyFont
$headingLineHeight: 1.2em
$headingTextColor: $defaultColor
$headingTextSecondaryColor: $grey500
$linkHoverColor: darken($primaryBlueColor, 10%)
$linkSecondaryColor: $grey500
$metaColor: $grey700
$mobileContentPadding: 16px
$mobileNavWidth: 240px
$sfMonoFont: "SF Mono", "Segoe UI Mono", "Roboto Mono", monospace
$pageBackgroundColor: $grey25
$pageHeaderBackgroundColor: $grey1000
$pageFooterBackgroundColor: #191919
$pageHeaderHeight: 135px
$postPreviewImageRatio: math.div(13, 9)
$postPublicationMetaColor: $grey500
$rightArrow: '\2192'
$searchButtonIconColor: #fff
$secondaryBackgroundColor: $grey25
$secondaryBorderColor: $grey100
$secondaryTitleColor: $grey500
$selectedItemBackgroundColor: #DBDBDB
$postImageBackgroundColor: $grey25
$postBorderColor: $grey50
$syllabusMobileImageRatio: math.div(15, 4)
$teaserTextColor: #8A8A8A
$breadcrumbTextColor: $grey700
$breadcrumbHighlightTextColor: $grey500

$topicsMenuSubmenuWidth: 335px
$topicsMenuWidth: 190px

$contentMaxWidescreenWidth: $eight-columns-wide
$contentMaxDesktopWidth: $six-columns-wide
$categoryHeaderImageMaxNonMobileHeight: 256px
$categoryHeaderImageMaxMobileHeight: 200px
$contentMarginTop: 45px
$contentHeadingMargin: 15px
$contentSectionMarginBottom: 30px
$callToActionButtonBackground: $primaryBlueColor
$callToActionButtonBackgroundHover: lighten($primaryBlueColor, 5%)
$social_icon_default_border: #949494
$social_icon_default_fill: #666666
$social_icon_fb_fill: #0865FE
$social_icon_x_fill: #0F1419
$social_icon_pinterest_fill: #E60023
$social_icon_linkedin_fill: #0077B5
$social_icon_link_fill: #444444
$social_icon_reddit_fill: #ff4500

///
@mixin highlighted-box
  padding: 20px
  margin: 0 0 26px 0
  background: $secondaryBackgroundColor
  font-size: 14px
  p
    margin: 10px 0 0

$postHeaderHeroImageTextColor: $white

$previewImageAspectRatio: math.div(400, 277)
$headerColor: #282828
