@import '../variables'
@import '../mixins/responsive'
@import '../mixins/topic-link'
@import '../mixins/button'

$old-mobile-nav-background-color:           #3e5166
$mobile-nav-background-color:               $pageHeaderBackgroundColor
$mobile-nav-top-level-background-color:     #384757
$mobile-nav-top-level-link-color:           $grey
$mobile-nav-top-level-active-link-color:    $white
$mobile-nav-top-level-expand-text-color:    #6D8299
$mobile-nav-second-level-background-color:  #1d2630
$mobile-nav-item-border-color:              $grey700
$search-box-highlighted-color:              #3c444c

$non-mobile-nav-sub-categories-menu-item-side-padding: 8px
$non-mobile-nav-sub-categories-menu-side-padding: calc(40px - $non-mobile-nav-sub-categories-menu-item-side-padding)
$non-mobile-nav-sub-categories-menu-side-padding-extra-space: 4px

.global-nav
  //flex: 0 0
  position: relative


.new-nav
  display: block
  +all-desktop
    position: fixed
    top: 0
    left: 0
    right: 0
    z-index: 2147483647
    transition: transform 0.4s

  .global-nav__mobile-new
    overflow: hidden

.scroll-down .new-nav
  +all-desktop
    transform: translate3d(0, -100%, 0)

.scroll-up .new-nav
  +all-desktop
    transform: none

.empty-sticky-nav-background-div
  +mobile-and-tablet
    height: 0 !important

.scroll-up .announcement-bar--open,
.scroll-down .announcement-bar--open
  display: none

// Global nav on desktop

.global-nav__header
  background: $pageHeaderBackgroundColor
  position: relative
  font-family: system-ui, Roboto, sans-serif

.body--mobile-nav-open
  .global-nav__header
    +mobile-and-tablet

.global-nav__header-content
  justify-content: space-between
  position:   relative
  height:     $pageHeaderHeight
  margin:     0 auto
  +wide
    max-width: $twelve-columns-wide
  +desktop
    max-width: $nine-columns-wide
  +tablet
    max-width: $six-columns-wide
  +mobile-and-tablet
    height:  75px
  +mobile
    margin: 0 10px

.global-nav__header-tuts-logo-link
  display: block

  .global-nav__header-tuts-logo-image
    margin-top: 3px
    width:      auto
    height:     26px

.global-nav__header-left
  display: inline-block
  position: absolute
  height: 100%

.global-nav__header-top
  display: block
  height: 50%
  width: 100%
  position: relative
  padding-top: 22px
  +mobile-and-tablet
    height: 100%

.global-nav__header-list
  list-style: none
  margin: 0
  padding: 0
  padding-top: 4px
  height: 30px
  line-height: 30px
  display: inline-block
  +mobile-and-tablet
    display: none

.global-nav__header-buttons
  list-style: none
  margin: 0
  padding: 0
  height: 30px
  line-height: 30px
  display: inline-block
  width: 100%
  text-align: right
  +mobile
    display: none
  .global-nav__header-item:last-child
    margin-right: 0

.global-nav__header-item
  display: inline-block
  margin: 0px 30px 0 0

.global-nav__header-item--free-account-button
  background-color: $envatoGreen
  padding: 0px 20px
  color: $white
  border-radius: 4px
  height: 100%
  display: inline-block
  font-weight: 500
  cursor: pointer
  &:hover
    transition: all 0.2s ease
    background-color: $envatoGreenHover
    color: $white
  &:active
    background-color: $envatoGreenActive

.global-nav__header-link
  display:            inline-block
  font-size:          16px
  color:              $white
  white-space: nowrap

  &:hover, &:active, &:focus
    transition: all 0.2s ease
    color:            $grey

.global-nav__header
  .fa-chevron-down
    font-size:        8px
    margin-left:      8px
    position:         relative
    top:              -2px

.global-nav__header-right--courses
  right: 0
  +desktop
    right: 96px
  +mobile
    display: block

.global-nav__concise-user-links
  +wide
    display: none
  +desktop
    display: none

.global-nav__user-menu
  position: relative
  padding-right: 20px
  text-align: left

.global-nav__user-menu-link
  display: block
  vertical-align: top
  position: relative
  color: $grey

.global-nav__user-menu-items
  display: none
  border-radius: 3px
  position: absolute
  right: 0
  padding: 0
  margin: 0
  min-width: 180px
  box-shadow: 0 2px 8px rgba(black, 0.3)
  list-style: none
  &:before
    content: ''
    display: block
    width: 0
    height: 0
    position: absolute
    border-left: 6px solid transparent
    border-right: 6px solid transparent
    border-bottom: 6px solid $white
    top: -6px
    right: 17px
  li.global-nav__user-menu-item:first-child a
    border-radius: 3px 3px 0 0
    padding-top: 20px
  li.global-nav__user-menu-item:last-child a
    border-top: 1px solid $grey
    border-radius: 0 0 3px 3px
    padding-bottom: 20px

.global-nav__user-menu-item--separator-before
  border-top: 1px solid black

.global-nav__user-menu-item--separator-after
  border-bottom: 1px solid black

.global-nav__user-menu-item-link
  position: relative
  display: inline-block
  width: 100%
  padding: 10px 20px
  font: normal 14px/17px $headingFont
  white-space: nowrap
  color: $linkSecondaryColor

  &:hover
    transition: all 0.2s ease
    font-weight: bold
    color: $defaultColor
    background: $contentBackgroundColor


.global-nav__user-menu-item-link--admin
  background: $pink25
  min-width: 200px
  &:hover
    transition: all 0.2s ease
    background: $pink25

.global-nav__user-menu:hover
  transition: all 0.2s ease
  z-index: 10

  .global-nav__user-menu-link
    color: $white
    // put this on top of topics to cover the top shadow
    z-index: 11

    i.fa-chevron-down:before
      transform: rotate(180deg)

  .global-nav__user-menu-items
    display: block

///////////////////////////////////////////////////////////////////
// Mobile Menu Mechanics (mostly)

.global-nav__mobile-toggle
  +wide
    display: none
  +desktop
    display: none
  position: absolute
  top: 0
  right: 0
  z-index: 2

.global-nav__mobile-toggle-link,
.global-nav__mobile-toggle-link:hover,
.global-nav__mobile-toggle-link:active,
.global-nav__mobile-toggle-link:focus
  transition: all 0.2s ease
  display:    block
  color:      $white
  padding:    0px 16px
  margin:     21px 13px
  .fa-reorder
    font-size:  24px
    line-height: 33px
    display: block
  .fa-times
    font-size: 18px
    line-height: 33px

    display: none

.body--mobile-nav-open
  .global-nav__mobile-toggle
    right: $mobileNavWidth

  .global-nav__mobile-toggle-link
    background: $envatoGreen
    border-radius: 3px
    .fa-reorder
      display: none
    .fa-times
      display: block

/////////////////////////////////////////////////////////////////
// Mobile Menu Content

.body--mobile-nav-open
  .global-nav__mobile-overlay
    +mobile-and-tablet
      position: fixed
      left: 0
      right: 0
      top: 0
      bottom: 0
      z-index: 1000
      background-color: $newBodyTextColor
      opacity: 0.8
      min-height: 100vh
  .global-nav__mobile-toggle
    +mobile-and-tablet
      z-index: 1001

.global-nav__mobile-search
  padding: 21px 14px 10px
  width: 100%
  position: relative
  &.new-nav-mobile
    padding: 21px 24px 10px

    +mobile
      padding-left: $mobileContentPadding
      padding-right: $mobileContentPadding

.global-nav__search-terms
  width: 100%
  border: none
  color: $newBodyTextColor
  background: $lightestGrey
  padding: 9px 8px
  border-radius: 6px
  font: 14px/1.6em $font
  &.search-terms-nonmobile
    width: 240px
    +wide
      width: 370px
  &:hover, &:focus, &:active
    transition: all 0.2s ease
    outline: 2px solid $envatoGreen

//
// Mobile global nav
//

.global-nav__mobile-top-level
  list-style:     none
  border-top:     solid 2px $mobile-nav-item-border-color
  padding: 15px 0 0
  border-bottom: solid 1px rgba(255, 255, 255, 0.1)
  margin:         0
  &.new-nav-mobile
    border-bottom: none
  &:first-of-type
    border-top: none

.global-nav-mobile__envato-links
  padding: 0

.global-nav__mobile-divider
  opacity: 0.5
  border: none
  border-top: 1px solid $grey100
  margin: 32px 24px
  +mobile
    margin-left: $mobileContentPadding
    margin-right: $mobileContentPadding

.global-mobile__header-item--envato-link
  display:        block
  position:       relative
  padding:        0 14px
  color:          $white
  &:hover, &:active, &:focus
    transition: all 0.2s ease
    color:        $mobile-nav-top-level-link-color

.global-nav__mobile-top-level-link.global-nav__mobile-top-level-link-new-nav
  width: 100%
  +mobile
    padding-left: $mobileContentPadding !important
    padding-right: $mobileContentPadding !important

.global-nav__mobile-top-level-link-icon
  margin-left:    5px
  vertical-align: middle

.global-nav__mobile-second-level-topic-link,
.global-nav__mobile-second-level-free-courses-link,
.global-nav__mobile-second-level-all-topics-link
  display:        block
  margin-bottom:  1px
  color:          $white
  line-height:    2.4em
  padding-left:   15px
  &:hover, &:active, &:focus
    transition: all 0.2s ease
    color: $grey

.global-nav__mobile-second-level-free-courses-link
  background: $mobile-nav-top-level-background-color
  &:hover
    transition: all 0.2s ease
    background: darken($mobile-nav-top-level-background-color, 5%)

.global-nav__mobile-second-level-all-topics-link
  background: $mobile-nav-top-level-background-color
  &:hover
    transition: all 0.2s ease
    background: darken($mobile-nav-top-level-background-color, 5%)

.global-nav__mobile-top-level-item
  font-size: 14px
  line-height: 2.9em

.global-nav__mobile-user-link
  color: $white
  display: block
  height: 44px
  padding: 0 14px
  margin: 0
  font-size: 14px
  line-height: 2.9em
  &:hover, &:active, &:focus
    transition: all 0.2s ease
    color: $grey

.global-nav__mobile-user-greeting
  color: #9BB4D0
  margin: 0 0 10px
  text-align: center
  display: block

.global-nav__mobile-user-greeting-name
  color: #9BB4D0
  font-weight: normal

.global-nav__mobile-closed
  .nav-actions
    display: none
    height: 96px
    background-color: $white
    border-bottom: 1px solid rgba(74, 74, 74, 0.1)
    justify-content: space-between
    grid-template-columns: repeat(12, 1fr)
    grid-template-rows: 1fr
    padding-top: 22px
    +mobile
      display: grid
      grid-template-areas: "menu . home home home home home home home home . search"
      padding-left: calc($mobileContentPadding - 4px)
      padding-right: calc($mobileContentPadding - 4px)
    +tablet
      display: grid
      grid-template-areas: "menu . . . home home home home . . . search"
    i
      color: $newBodyTextColor
      font-size: 16pt

.mobile-header-action
  padding-top: 24px
  text-align: center

.mobile-home-logo
  grid-area: home

  .global-nav__header-tuts-logo-image
    margin-top: -2px

.mobile-menu-icon
  grid-area: menu

.mobile-search-icon
  grid-area: search

.mobile-back-icon
  grid-area: back

.mobile-close-icon
  grid-area: close

.mobile-action-icon
  padding-top: 5px
  text-align: center

.global-nav__mobile-open-header
  grid-template-columns: repeat(12, 1fr)
  +mobile
    grid-template-areas: "back . home home home home home home home home . close"
    padding-left: calc($mobileContentPadding - 4px)
    padding-right: calc($mobileContentPadding - 4px)
  +tablet
    grid-template-areas: "back . . . home home home home . . . close"
  display: grid
  justify-content: space-between
  padding-top: 22px
  i
    color: $newBodyTextColor
    font-size: 18pt

.global-nav__search-button
  position: absolute
  top: 26px
  right: 16px
  color: $grey500
  background: transparent
  border: none
  margin: 0
  padding: 0
  font-size: 16px
  cursor: pointer

  +mobile-and-tablet
    top: 32px
    right: 36px
    font-size: 18px

.global-nav__mobile-new
  display: none
  width: $mobileNavWidth
  padding: 0
  position: absolute
  top: 0
  right: 0
  min-height: 100vh
  background: $white
  width: 100%

  .global-nav__mobile-top-level-link
    padding: 8px 24px
    color: $newBodyTextColor
    font-size: 16px
    font-weight: 500
    display: block
    &:hover, &:active, &:focus
      transition: all 0.2s ease

  .global-nav__mobile-top-level-item--expanded
    background-color: $white

  .global-nav__mobile-top-level-expand-text
    right: 24px
    top: 8px
    color: $newBodyTextColor
    display: block
    font: 14px/2.9em $font
    float: right

  .global-nav__mobile-user-link, .global-mobile__header-item--envato-link
    padding: 0 24px
    color: $newBodyTextColor
    +mobile
      padding: 0 $mobileContentPadding

.body--mobile-nav-open
  .global-nav__mobile-new
    +mobile-and-tablet
      display: block
      z-index: 1001

.global-new-nav__header
  background: $newBodyTextColor
  position: relative
  font-family: system-ui, Roboto, sans-serif

  .global-new-nav__header-link
    display:            inline-block
    font-size:          12px
    color:              $breadcrumbTextColor
    white-space: nowrap

  .global-nav__header-item--envato-link, .global-new-nav__user-link
    display:            inline-block
    font-size:          12px
    color:              #4A4A4A
    white-space: nowrap

  .global-nav__header-item 
    color: $headerColor
  
  .global-nav__header-tuts-logo-image
    margin-bottom: -8px

  .global-new-nav__header-link
    color: $newBodyTextColor

  .global-nav__header-link--primary
    font-size: 14px
    font-weight: 500

  .global-new-nav__header-divider
    color: $breadcrumbTextColor
    opacity: 0.1   

  .global-new-nav__header-list
    list-style: none
    margin: 0
    padding: 0
    // padding-top: 4px
    // height: 30px
    line-height: 34px
    display: inline-block
    +mobile-and-tablet
      display: none

  .global-nav__header-list > li:not(.global-nav__header-item-tuts-logo)
    & > a
      padding: 16px 0px 14px

      span
        color: $headerColor
        padding: 0px 9px 0px

    &:hover, &:active, &:focus
      border-bottom: 4px solid #96CE73

    &.global-nav__top-level-item--expanded
      border-bottom: 4px solid #96CE73

  .global-nav__search-form
    margin-top: 1px

  .global-new-nav__header-item
    display: inline-block
    margin: 0px 0px 0 30px

  .global-new-nav__header-content
    justify-content: space-between
    position:   relative
    background: $white
    margin:     0 auto
    border-bottom: 1px solid $postBorderColor

    .global-new-nav__header-right
      display: inline-block
      font-size: 12px
      +tablet
        right: 40px
      +mobile
        display: none

    .global-new-nav__header-top
      height: 34px
      background-color: $lightestGrey
      +wide
        padding: 0px calc((100% - $twelve-columns-wide) / 2)
      +desktop
        padding: 0px calc((100% - $nine-columns-wide) / 2)
      +tablet
        padding: 0px calc((100% - $six-columns-wide) / 2)

      .global-new-nav__header-right
        float: right

    .global-new-nav__header-bottom
      height: 68px
      line-height: 68px
      +wide
        max-width: $twelve-columns-wide
      +desktop
        max-width: $nine-columns-wide
      +tablet
        max-width: $six-columns-wide

      .global-new-nav__header-right
        position: absolute
        right: 0

    .global-new-nav__header-top, .global-new-nav__header-bottom
      justify-content: space-between
      position:   relative
      margin:     0 auto
      display: block
      width: 100%
      position: relative
      +mobile-and-tablet
        height:  75px
        display: none
      +mobile
        margin: 0 10px

.global-nav__mobile-top-level-item--expanded
  .global-nav__mobile-second-level-new > .global-nav__mobile-new
    left: 0vw

.global-nav__mobile-second-level-link-item--expanded
  .global-nav__mobile-third-level-new > .global-nav__mobile-new
      left: 0vw

.global-nav__mobile-second-level-new > .global-nav__mobile-new,
.global-nav__mobile-third-level-new > .global-nav__mobile-new
    left: 100vw

.global-nav__mobile-second-level-new,
.global-nav__mobile-third-level-new
  .mobile-action-icon,
  .global-nav__mobile-third-level-heading
    line-height: 2.9em

  .global-nav__mobile-third-level-heading
    &.global-nav__mobile-third-level-heading-without-link
      padding-bottom: 24px !important

  .global-nav__mobile-new
    transition: all 0.5s
  
    *
      color: $newBodyTextColor

    .global-nav__mobile-second-level-heading,
    .global-nav__mobile-third-level-heading
      padding: 24px 24px 16px

      +mobile
        padding: 24px $mobileContentPadding 16px
      
      .global-nav__mobile-second-level-heading-title,
      .global-nav__mobile-third-level-heading-title
        display: block
        font-weight: 700
        font-size: 18px
        line-height: 21px
        letter-spacing: inherit

      .global-nav__mobile-second-level-heading-link,
      .global-nav__mobile-third-level-heading-link
        font-weight: 500
        font-size: 14px
        line-height: 17px

        .global-nav__mobile-second-level-heading-link-text,
        .global-nav__mobile-third-level-heading-link-text
          letter-spacing: -0.154px

        .global-nav__mobile-second-level-heading-link-icon,
        .global-nav__mobile-third-level-heading-link-icon
          margin-left: 7px

    .global-nav__mobile-second-level-links-title,
    .global-nav__mobile-third-level-links-title
      margin: 0px 24px
      padding: 24px 0px 16px
      font-weight: 700
      font-size: 12px
      line-height: 14px
      text-transform: uppercase
      color: $mediumGrey
      border-top: 1px solid $grey100

      +mobile
        margin: 0px $mobileContentPadding

      &.empty-title
        padding: 12px 0px 0px

    .global-nav__mobile-second-level-links,
    .global-nav__mobile-third-level-links
      margin: 0px
      margin-bottom: 16px
      padding: 0px
      list-style: none

      .global-nav__mobile-second-level-link-item,
      .global-nav__mobile-third-level-link-item
        font-size: 14px
        line-height: 2em

        .global-nav__mobile-second-level-link,
        .global-nav__mobile-third-level-link
          padding: 8px 24px
          font-weight: 500
          width: 100%
          display: block

          +mobile
            padding: 8px $mobileContentPadding

        .global-nav__mobile-second-level-link-icon
          float: right

.global-nav__mobile-second-level-link-item,
.global-nav__mobile-third-level-link-item
  .global-nav__mobile-popular-software-icon
    width: 16px
    height: 16px
    margin-bottom: -3px
    margin-right: 8px

.global-new-nav__header-content
  .global-nav__header-item
    margin: 0px
    padding: 0px
    cursor: pointer
    margin: 0px 1px

    .global-new-nav__header-link
      padding: 16px 9px 13px

    .global-new-nav__header-second-level
      display: none
      position: absolute
      top: 69px
      left: 0px
      z-index: 1
      background-color: $white
      filter: drop-shadow(0px 15px 10px rgba(0, 0, 0, .1))
      width: 100vw
      cursor: auto
      +mobile-and-tablet
        display: none
      +wide
        left: calc((100vw - $twelve-columns-wide) / -2)
      +desktop
        left: calc((100vw - $nine-columns-wide) / -2)
      +tablet
        left: calc((100vw - $six-columns-wide) / -2)

      .global-nav__header-second-level-list
        display: flex
        align-items: stretch
        padding: 0px
        margin: auto
        +wide
          max-width: $twelve-columns-wide
        +desktop
          max-width: $nine-columns-wide
        +tablet
          max-width: $six-columns-wide

        .global-nav__header-item
          display: flex
          align-items: center
          padding: 16px 9px
          cursor: auto
          margin: 0px

          +desktop
            padding: 16px 5px

          &.no-chevron
            padding: 16px 9px !important

          &:first-child
            padding-left: 0px

          .global-new-nav__header-link
            padding: 0px

      .global-nav__header-item
        .global-new-nav__header-link
          color: $headerColor !important
          display: flex
          align-items: center
          line-height: 20px

          i
            margin-left: 3px
            font-weight: 600
            color: $grey300
            font-size: 10px

            &.fa-ellipsis-vertical
              margin-left: 0px
              font-size: 16px
              color: $newBodyTextColor
        
        .global-new-nav__header-third-level
          display: flex
          visibility: hidden
          max-height: 0px
          background-color: $white
          position: absolute
          top: 100%
          filter: drop-shadow(0px 15px 10px rgba(0, 0, 0, .1))
          border-top: 1px solid $postBorderColor

          .global-new-nav__header-third-level-sub-categories,
          .global-new-nav__header-third-level-popular-softwares
            margin: 32px 0px
            padding: 0px $non-mobile-nav-sub-categories-menu-side-padding
            display: flex
            flex-direction: column

            li > *,
            > a
              padding: 0px $non-mobile-nav-sub-categories-menu-item-side-padding

            .global-new-nav__header-third-level-links-title
              font-weight: 700
              font-size: 12px
              line-height: 14px
              text-transform: uppercase
              color: $mediumGrey
              margin-bottom: 12px
              white-space: nowrap

            .global-new-nav__header-third-level-links
              margin: 0px
              padding: 0px
              list-style: none

              &.display-flex
                display: flex

                ul
                  margin: 0px
                  padding: 0px
                  list-style: none
                  display: block
                  width: 100%

                  &:nth-child(n)
                    margin-left: $non-mobile-nav-sub-categories-menu-side-padding

                  &:first-child
                    margin-left: 0px

              .global-new-nav__header-third-level-link-item
                margin: 6px 0px

                .global-new-nav__header-third-level-link
                  font-weight: 400
                  font-size: 14px
                  letter-spacing: -0.154px
                  color: $newBodyTextColor
                  line-height: 32px
                  display: inline-flex
                  width: 100%
                  height: 100%
                  align-items: center
                  white-space: nowrap

                  .global-new-nav__header-popular-software-icon
                    width: 16px
                    height: 16px
                    margin-right: 12px

                  &:hover, &:active, &:focus
                    border-radius: 8px
                    background-color: $backgroundGrey

          .global-new-nav__header-third-level-sub-categories
            .global-new-nav__header-third-level-heading-link
              font-weight: 700
              font-size: 14px
              line-height: 32px
              display: inline-block
              white-space: nowrap
              color: $newBodyTextColor

              .global-new-nav__header-third-level-heading-link-icon
                margin-left: 6px
              
              &:hover, &:active, &:focus
                border-radius: 8px
                background-color: $backgroundGrey

          .global-new-nav__header-third-level-popular-softwares
            border-left: 1px solid $grey100

        &:hover, &:active, &:focus, &:focus-within,
        &.active-software,
        &.active-category
          .global-new-nav__header-link
            padding: 6px 8px
            border-radius: 8px
            background-color: $grey50
            margin: -6px -8px

            &.global-new-nav__header-more-link
              padding: 8px 12px
              margin: -8px -12px

              .fa-ellipsis-vertical
                background-color: $backgroundGrey

            +desktop
              padding: 6px 4px
              margin: -6px -4px

          &.no-chevron
            .global-new-nav__header-link
              padding: 6px 8px !important
              margin: -6px -8px !important

        &.global-nav__header-item-courses-separator,
        &.global-nav__header-item-courses
          display: none
          +wide
            display: flex
        
        &.global-nav__header-item-courses-separator
          .global-nav__header-item-separator
            height: 100%
            border-left: 1px solid $grey300

        &.global-nav__header-item-more-link
          padding: 16px 13px

          +desktop
            padding: 16px 9px

          .global-nav__header-second-level-more-links
            visibility: hidden
            opacity: 0
            filter: drop-shadow(0px 15px 10px rgba(0, 0, 0, .1))
            transition: visibility .1s linear .2s, opacity 0s linear 0.2s
            margin: 0px
            margin-left: -157px
            padding: 8px 24px 16px
            position: absolute
            background-color: $white
            flex-direction: column
            top: 100%
            border-top: 1px solid $postBorderColor

            .global-nav__header-item
              padding: 0px

              .global-new-nav__header-link
                border-radius: 0px
                color: $newBodyTextColor !important
                background-color: transparent !important
                border-radius: 8px
                width: 100%
                margin: 4px 0px
                padding: 6px 8px

                &:hover, &:active, &:focus
                  background-color: $backgroundGrey !important

              &.global-nav__header-item-courses
                display: flex
                +wide
                  display: none

          &:hover, &:active, &:focus, &:focus-within
            .global-nav__header-second-level-more-links
              visibility: visible
              opacity: 1
              transition: visibility 0s linear 0s, opacity 0s linear

    &:hover, &:active, &:focus
      .global-new-nav__header-link
        color: $newBodyTextColor
    
    &.global-nav__top-level-item--expanded
      padding: 0px

      .global-new-nav__header-second-level
        display: block

  .global-nav__header-item-tuts-logo
    margin-right: 15px
    margin-left: 0px
    padding: 0px

.global-nav__header-link--primary
  cursor: pointer

.global-new-nav__header
  .global-new-nav__header-content
    .global-nav__header-buttons
      .global-nav__user-menu
        .global-nav__user-menu-link
          .fa-user-circle
            margin-right: 9px

        &:hover
          .global-nav__user-menu-link
            color: $newBodyTextColor

        .global-nav__user-menu-items
          &::before
            right: 16px

          right: 8px
          padding: 16px 0px
          background-color: $white
          border-radius: 8px

          .global-nav__user-menu-item
            padding: 2px 8px

            &.global-nav__user-menu-item--separator-before
              border-top: 1px solid $postBorderColor
              margin-top: 8px
              padding-top: 10px

            &.global-nav__user-menu-item--separator-after
              border-bottom: 1px solid $postBorderColor
              margin-bottom: 8px
              padding-bottom: 10px

            & > a
              padding: 8px 16px
              border-radius: 4px
              color: $newBodyTextColor
              line-height: 150%
              letter-spacing: -0.154px

              &.global-nav__user-menu-item-link
                border-top: none

              &.global-nav__user-menu-item-link--admin
                background-color: $white

              &:hover, &:active, &:focus
                background-color: $backgroundGrey
                font-weight: normal

.global-new-nav__skip-to-content-link
  position: absolute
  height: 80px
  width: 148px
  left: 16px
  top: 16px
  z-index: -1
  font-weight: 600
  font-size: 16px
  line-height: 19px
  letter-spacing: -0.32px
  background: $white
  display: flex
  justify-content: center
  align-items: center

  &:focus
    z-index: 1
    color: $pageHeaderBackgroundColor

.no-chevron
  .fa.fa-chevron-down
    display: none