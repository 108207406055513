@import '../mixins/three-column-card'
@import '../variables'

.ecosystem-highlight
  background-color: $grey700
  font-family: $bodyFont
  width: 100%
  padding: 80px 0
  text-align: center
  +tablet
    height: auto
    padding: 40px 0
    text-align: center

  +mobile
    display: none

.ecosystem-highlight__cards
  @include container

.ecosystem-highlight__card
  @include three-column-full-width-card
  min-height: 300px
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08)
  background: $white
  border: solid 1px $grey700
  border-radius: 4px
  text-align: left

  +desktop
    &:nth-child(n+4)
      display: none

  +tablet
    &:nth-child(n+3)
      display: none

  a
    color: $bodyTextColor

.ecosystem-highlight__card-image
  width: 100%
  height: 150px

.ecosystem-highlight__card-content
  padding: 10px

.ecosystem-highlight__card-title
  font-size: 18px
  font-weight: 500
  line-height: 24px
  color: #444444

.ecosystem-highlight__card-description
  font-size: 16px
  font-weight: 400
  line-height: 22px
  margin-top: 10px
  color: $grey500
