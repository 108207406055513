// Stupid SASS scoping
$topic-color: #FFFFFF
$dark-topic-color: #aaa

=topic-colors
  &.topic-design
    $topic-color:       #C62E46 !global
    $dark-topic-color:  #FFF2EF !global
    @content
  &.topic-code
    $topic-color:       #2E7400 !global
    $dark-topic-color:  #ECF6E5 !global
    @content
  &.topic-photography
    $topic-color:       #4635C5 !global
    $dark-topic-color:  #EEECFD !global
    @content
  &.topic-business
    $topic-color:       #D74000 !global
    $dark-topic-color:  #FFF1E9 !global
    @content
  &.topic-webdesign
    $topic-color:       #005B5C !global
    $dark-topic-color:  $teal25 !global
    @content
  &.topic-photo
    $topic-color:       #4635C5 !global
    $dark-topic-color:  #EEECFD !global
    @content
  &.topic-video
    $topic-color:       #035DD9 !global
    $dark-topic-color:  #E6F0FF !global
    @content
  &.topic-music
    $topic-color:       #036DFF !global
    $dark-topic-color:  #E6F0FF !global
    @content
  &.topic-cgi
    $topic-color:       #F95858 !global
    $dark-topic-color:  #E03F3F !global
    @content
  &.topic-computers
    $topic-color:       #5D7DBA !global
    $dark-topic-color:  #4464A1 !global
    @content
  &.topic-crafts
    $topic-color:       #ff9069 !global
    $dark-topic-color:  #E67750 !global
    @content
  &.topic-gamedevelopment
    $topic-color:       #72BF40 !global
    $dark-topic-color:  #59A627 !global
    @content
