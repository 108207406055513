@import '../mixins/responsive'

.system-banner

  .system-banner-alert-box
    margin-top: -16px
    margin-bottom: 32px
    padding: 16px
    background: whitesmoke
    border-left: 6px solid #EFA506
    border-radius: 4px
    display: flex
    font-size: 14px
    font-weight: 400
    line-height: 1.5
    color: #666666
    align-items: flex-start

    +mobile
      margin-top: 20px
      margin-bottom: 30px

    .system-banner-alert-box__icon
      color: #EFA506
      margin-right: 16px
      margin-top: 1px

      +mobile
        display: none !important

      .system-banner-alert-box__icon-flag
        width: 15px

    .system-banner-alert-box__message

      .system-banner-alert-box__message-body
        margin: 0px

        a
          color: #0084B4 !important

          &:hover
            text-decoration: underline !important


