/* FONT PATH */

.fa,
.fas,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("fonts/fa-solid-900.eot");
  src: url("fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("fonts/fa-solid-900.woff2") format("woff2"), url("fonts/fa-solid-900.woff") format("woff"), url("fonts/fa-solid-900.ttf") format("truetype"), url("fonts/fa-solid-900.svg#fontawesome") format("svg");
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/fa-regular-400.eot");
  src: url("fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("fonts/fa-regular-400.woff2") format("woff2"), url("fonts/fa-regular-400.woff") format("woff"), url("fonts/fa-regular-400.ttf") format("truetype"), url("fonts/fa-regular-400.svg#fontawesome") format("svg");
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/fa-brands-400.eot");
  src: url("fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("fonts/fa-brands-400.woff2") format("woff2"), url("fonts/fa-brands-400.woff") format("woff"), url("fonts/fa-brands-400.ttf") format("truetype"), url("fonts/fa-brands-400.svg#fontawesome") format("svg");
}

@font-face {
  font-family: 'PolySansWide Neutral';
  font-weight: 400;
  font-style: normal;
  src: url('fonts/PolySans-NeutralWide.otf');
}

@font-face {
  font-family: 'PolySansWide Median';
  font-weight: 600;
  font-style: normal;
  src: url('fonts/PolySans-MedianWide.otf');
}

@font-face {
  font-family: 'PolySansWide Bulky';
  font-weight: 700;
  font-style: normal;
  src: url('fonts/PolySans-BulkyWide.otf');
}

@font-face {
  font-family: 'PolySans Bulky';
  font-weight: 700;
  font-style: normal;
  src: url('fonts/PolySans-Bulky.otf');
}

@font-face {
  font-family: 'PolySans Slim';
  font-weight: 200;
  font-style: normal;
  src: url('fonts/PolySans-Slim.otf');
}

@font-face {
  font-family: 'PolySans Neutral';
  font-weight: 400;
  font-style: normal;
  src: url('fonts/PolySans-Neutral.otf');
}

@font-face {
  font-family: 'PolySans Median';
  font-weight: 600;
  font-style: normal;
  src: url('fonts/PolySans-Median.otf');
}

.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

.far {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

.fab {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
          animation: fa-spin 2s infinite linear;
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

// /* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen

.fa-search:before,
.fa-magnifying-glass:before {
  content: "\f002";
}

.fa-check:before {
  content: "\f00c";
}

.fa-remove:before,
.fa-times:before {
  content: "\f00d";
}

.fa-cog:before {
  content: "\f013";
}

.fa-lock:before {
  content: "\f023";
}

.fa-book:before {
  content: "\f02d";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-check-circle:before {
  content: "\f058";
}

.fa-ban:before {
  content: "\f05e";
}

.fa-arrow-left:before {
  content: "\f060";
}

.fa-arrow-right:before {
  content: "\f061";
}

.fa-plus:before {
  content: "\f067";
}

.fa-minus:before {
  content: "\f068";
}

.fa-warning:before,
.fa-exclamation-triangle:before {
  content: "\f071";
}

.fa-comment:before {
  content: "\f075";
}

.fa-chevron-up:before {
  content: "\f077";
}

.fa-chevron-down:before {
  content: "\f078";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-facebook-f:before,
.fa-facebook:before {
  content: "\f09a";
}

.fa-github:before {
  content: "\f09b";
}

.fa-credit-card:before {
  content: "\f09d";
}

.fa-feed:before,
.fa-rss:before {
  content: "\f09e";
}

.fa-reorder:before,
.fa-bars:before {
  content: "\f0c9";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-money:before {
  content: "\f0d6";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-linkedin-in:before {
  content: "\f0e1";
}

.fa-undo:before {
  content: "\f0e2";
}

.fa-cloud-download-alt:before {
  content: "\f0ed";
}

.fa-cloud-upload-alt:before {
  content: "\f0ee";
}

.fa-file-text-o:before {
  content: "\f0f6";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before {
  content: "\f105";
}

.fa-unlock-alt:before {
  content: "\f13e";
}

.fa-play-circle:before {
  content: "\f144";
}

.fa-graduation-cap:before {
  content: "\f19d";
}

.fa-circle-notch:before {
  content: "\f1ce";
}

.fa-weixin:before {
  content: "\f1d7";
}

.fa-share-alt:before {
  content: "\f1e0";
}

.fa-user-circle:before {
  content: "\f2bd";
}

.fa-forward:before {
  content: "\f04e";
}

.fa-heart:before {
  content: "\f004";
}

.fa-arrows:before {
  content: "\f047";
}

.fa-linkedin-square:before {
  content: '\f08c';
}

.fa-globe {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

.fa-globe:before {
  content: '\f0ac';
}

.fa-clock:before {
  content: "\f017"
}

.fa-book-open:before {
  content: "\f518";
}

.fa-etsy:before {
  content: '\f2d7';
}

.fa-instagram-square:before {
  content: '\e055';
}

.fa-thumbs-up:before {
  content: '\f164';
}

.fa-thumbs-down:before {
  content: '\f165';
}

.fa-comment-dots:before {
  content: '\f4ad';
}

.fa-circle-play:before {
  content: '\f144';
}

.fa-list:before {
  content: '\f03a';
}

.fa-ellipsis-vertical:before {
  content: '\f142';
}

.fa-circle-chevron-right:before {
  content: '\f138';
}

.fa-circle-chevron-left:before {
  content: '\f137';
}

.fa-play:before {
  content: '\f04b';
}

.fa-star:before {
  content: '\f005';
}

.fa-link:before {
  content: '\f0c1';
}
