@import '../mixins/responsive'
@import '../variables'

$announcement-bar-height: 52px

=announcement-link($linkColor: $primaryBlueColor)
  font-weight: bold
  color: $linkColor
  text-decoration: underline
  &:hover, &:active, &:focus
    color: darken($linkColor, 5%)
    text-decoration: underline

.announcement-bar
  display: none
  width: 100%
  height: $announcement-bar-height
  line-height: $announcement-bar-height
  font-size: 16px
  color: $grey700
  background-repeat: repeat
  background-size: cover
  position: relative
  +mobile
    font-size: 14px

.announcement-bar--open
  display: block

.announcement-bar--open ~ .global-nav__mobile-toggle
  top: $announcement-bar-height

.announcement-bar__content
  +container
  display: flex
  flex-flow: row nowrap
  align-items: center
  height: $announcement-bar-height // stupid firefox
  +mobile
    width: 100%
    padding: 0 10px
    overflow: hidden

.announcement-bar__primary
  white-space: nowrap
  flex: 1 0 auto
  display: flex
  flex-flow: nowrap
  align-items: center
  height: $announcement-bar-height // stupid firefox
  justify-content: center

.announcement-bar__secondary
  text-align: right
  flex: 0 0 auto
  height: $announcement-bar-height // stupid firefox
  position: absolute
  right: 14px

.announcement-bar__title
  height: $announcement-bar-height // stupid firefox
  line-height: $announcement-bar-height // stupid firefox
  +non-mobile
    display: inline-block
  +mobile
    display: block
    white-space: normal
    margin: 10px 0
    padding: 0
    width: 100%

.announcement-bar__title-desktop
  +non-mobile
    display: inline-block
  +mobile
    display: none

.announcement-bar__title-mobile
  +non-mobile
    display: none
  +mobile
    display: inline-block


// Not BEM but must attach styles to links inserted from CMS
.announcement-bar__title a
  +announcement-link
  display: inline-block
  +mobile
    margin: 0
    padding: 0
    line-height: 16px

.announcement-bar__close-link
  +announcement-link
  display: inline-block
  white-space: nowrap

.announcement-bar__close-icon
  margin-left: 4px
  vertical-align: middle
  font-size: 16px
  color: #000000

.announcement-bar__close-label
  display: none

.announcement-bar__icon-container
  overflow: hidden
  max-height: $announcement-bar-height
  margin-right: 20px
  +tablet
    max-width: 120px

.announcement-bar__icon
  max-height: $announcement-bar-height
  +mobile
    display: none

.announcement-bar__desktop-only
  +non-desktop
    display: none !important

.announcement-bar__title
  a.announcement-bar__title-link-desktop
    +non-mobile
      display: inline-block
    +mobile
      display: none

  a.announcement-bar__title-link-mobile
    padding: 0px 10px
    margin: 10px 5px
    +non-mobile
      display: none
    +mobile
      display: inline-block
