@import 'responsive'

@mixin three-column-card
  position: relative
  float: left
  width: $three-columns-wide
  margin-right: $gutter-width
  +mobile
    margin-right: auto
    margin-left: auto
    float: none

@mixin three-column-full-width-card
  @include three-column-card

  +wide
    &:nth-child(4n)
      margin-right: 0
  +desktop
    &:nth-child(3n)
      margin-right: 0
  +tablet
    &:nth-child(2n)
      margin-right: 0


@mixin three-column-card-extended
  position: relative
  float: left
  width: $three-columns-wide-extended
  margin-right: $half-gutter-width
  +mobile
    margin-right: auto
    margin-left: auto
    float: none

@mixin three-column-full-width-card-extended
  @include three-column-card-extended

  +wide
    &:nth-child(3n)
      margin-right: 0
  +desktop
    &:nth-child(2n)
      margin-right: 0
      margin-bottom: 15px
