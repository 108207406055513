@import '../variables'

.error-page
  margin: 100px 0 40px
  text-align: center

.error-page__header-image
  width: 112px
  margin: auto

.error-page__title
  font-family: $headingFont
  font-size: 31px

.error-page__debug
  clear: both
  margin: 20px 0
  font-size: 11px
  overflow: auto
  text-align: left
  background: darken($contentBackgroundColor, 5%)
  padding: 20px

.error-page__debug-inspect
  font-size: 14px
  font-weight: bold

.error-page__footer
  overflow: hidden
  padding-top: 40px
  border-top: solid 1px rgba($borderColor, 0.2)
  margin: 200px 0 80px

.error-page__tuts-logo
  width: 120px
  float: left

.error-page__envato-logo
  width: 80px
  float: right
