@use "sass:math"
// Grid layout
//
// +non-mobile
//  ██ ░░ ██ ░░ ██ ░░ ██ ░░ ██ ░░ ██ ░░       (12)  +wide    (>=1340px)
//  ██ ░░ ██ ░░ ██ ░░ ██ ░░ ██ ░░ ██ ░░
//  ██ ░░ ██ ░░ ██ ░░ ██ ░░ ██                (9)   +desktop (>=1024px)
//  ██ ░░ ██ ░░ ██ ░░ ██ ░░ ██
//  ██ ░░ ██ ░░ ██ ░░                         (6)   +tablet  (>=680px)
//  ██ ░░ ██ ░░ ██ ░░
//
// +mobile
//  (no grid, >=480px)                        (-)   +mobile-landscape
//  (no grid, <480px)                         (-)   +mobile-portrait

$column-width:            80px
$column-width-more:       80px
$gutter-width:            30px
$half-gutter-width:       math.div($gutter-width, 2)

$one-column-wide:               1 * $column-width + 1 * $gutter-width
$two-columns-wide:              2 * $column-width + 1 * $gutter-width
$three-columns-wide-extended:   3 * $column-width-more + 1 * $gutter-width + 3px
$three-columns-wide:            3 * $column-width + 2 * $gutter-width
$four-columns-wide:             4 * $column-width + 3 * $gutter-width
$five-columns-wide:             5 * $column-width + 4 * $gutter-width
$six-columns-wide:              6 * $column-width + 5 * $gutter-width
$seven-columns-wide:            7 * $column-width + 6 * $gutter-width
$eight-columns-wide:            8 * $column-width + 7 * $gutter-width
$nine-columns-wide:             9 * $column-width + 8 * $gutter-width
$ten-columns-wide:              10 * $column-width + 9 * $gutter-width
$eleven-columns-wide:           11 * $column-width + 10 * $gutter-width
$twelve-columns-wide:           12 * $column-width + 11 * $gutter-width

// deprecated
$thirteen-columns-wide:   13 * $column-width + 12 * $gutter-width
$fourteen-columns-wide:   14 * $column-width + 13 * $gutter-width
$fifteen-columns-wide:    15 * $column-width + 14 * $gutter-width
$sixteen-columns-wide:    16 * $column-width + 15 * $gutter-width
$seventeen-columns-wide:  17 * $column-width + 16 * $gutter-width

$breakpoint-wide:           1340px
$breakpoint-mini-wide:      1290px
$breakpoint-desktop:        1024px
$breakpoint-tablet:         680px
$breakpoint-mobile:         480px
$breakpoint-mobile-small:   320px

// Non-mobile

@mixin non-mobile
  @media (min-width: $breakpoint-tablet)
    @content

@mixin wide
  @media (min-width: $breakpoint-wide)
    @content

@mixin mini-wide
  @media (min-width: $breakpoint-mini-wide)
    @content

@mixin desktop
  @media (min-width: $breakpoint-desktop) and (max-width: $breakpoint-wide - 1)
    @content

@mixin tablet
  @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop - 1)
    @content

// Mobile

@mixin mobile
  @media (max-width: $breakpoint-tablet - 1)
    @content

@mixin mobile-landscape
  @media (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet - 1)
    @content

@mixin mobile-portrait
  @media (max-width: $breakpoint-mobile - 1)
    @content

// Mobile Small

@mixin mobile-small
  @media (max-width: $breakpoint-mobile - 1)
    @content

@mixin mobile-small-landscape
  @media (min-width: $breakpoint-mobile-small) and (max-width: $breakpoint-mobile - 1)
    @content

@mixin mobile-small-portrait
  @media (max-width: $breakpoint-mobile-small - 1)
    @content

// Combos

=mobile-and-tablet
  @media (max-width: $breakpoint-desktop - 1)
    @content

=non-desktop
  @media (max-width: $breakpoint-desktop - 1)
    @content

=all-desktop
  @media (min-width: $breakpoint-desktop)
    @content

// Container

@mixin container
  +non-mobile
    margin: 0 auto
    &:after
      content: ""
      display: table
      clear: both
  +wide
    max-width: $twelve-columns-wide
  +desktop
    max-width: $nine-columns-wide
  +tablet
    max-width: $six-columns-wide

@mixin container-no-clearfix
  +non-mobile
    margin: 0 auto
  +wide
    max-width: $twelve-columns-wide
  +desktop
    max-width: $nine-columns-wide
  +tablet
    max-width: $six-columns-wide
