@import '../variables'

.search__auto_suggestions_container
  top: 102px
  position: absolute
  text-align: left
  z-index: 10
  display: none
  +mobile
    top: 70px
  +tablet
   top: 60px
  &.home_hero
    .search__auto_suggesstion_list_item
      font-size: 0.9em !important

.search__auto_suggesstion_list_content
  background: $white
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 15%)
  border-radius: 8px
  padding: 12px 0px 12px 0px

.search__auto_suggesstion_list
  margin: 0
  padding: 0px
  list-style: none
  .search__auto_suggesstion_list_item
    line-height: 34px
    font-size: 1.2em
    padding: 0px 10px 0px 10px
    &:hover
      background: $grey50
    +mobile-and-tablet
      font-size: 0.9em

    .search__auto_suggesstion_item_content
      width: 100%
      .search__auto_suggesstion_link
        color: $grey1000
        display: block
        width: 100%
        .search__auto_suggesstion_link_name
          font-weight: 600
        .search__auto_suggesstion_link_label
          font-size: 12px
          color: $grey300