.sign-up-component
   .custom-underline
      position: relative
      display: inline-block
      &:after
        content: ''
        position: absolute
        left: 0
        right: 0
        width: 100%
        bottom: 4.5px /* Adjust to position the line exactly at the y descender */
        height: 1px  /* Adjust thickness of the line */
        background-color: #707070 /* Color of the underline */
