@use "sass:math"
@import '../variables'
@import '../mixins/placeholder'

$bCorpLinkOuterWidth:   70px

// Layout

.page-footer
  background: $pageFooterBackgroundColor
  font-size: 14px
  line-height: 1.6em
  font-family: system-ui, Roboto, sans-serif


.page-footer__content
  padding-bottom: 20px
  padding-top:    50px
  +container
  +mobile
    padding:      $mobileContentPadding

.page-footer__links
  font-size: 0

.page-footer__links-column
  font-size:      14px
  width:          $three-columns-wide
  display:        inline-block
  vertical-align: top
  margin-right: 0
  +desktop
    width:        $two-columns-wide
  +tablet
    width:        $two-columns-wide
  +mobile
    width: 100%
    text-align: center
    margin-bottom: 0
    border-top: solid 1px $grey700

.page-footer__links-last-column
  margin-right: 0
  +wide
    width:          ($three-columns-wide + $bCorpLinkOuterWidth)
    float: right
  +desktop
    width:        ($three-columns-wide + $bCorpLinkOuterWidth)
    float: right
  +tablet
    width:        100%
    display:      block
    margin-right: 0
    margin-top: 30px
  +mobile
    padding-top: 20px

.page-footer__brands-wrapper
  display: flex
  +mobile
    justify-content: center

.page-footer__bcorp-brand
  margin: 7px 20px 40px
  +mobile
    margin: 7px 0px 40px 10px

  .page-footer__bcorp-brand-link
    img
      height: 80px
      width: 50px
      +mobile
        height: 72px

// Common elements

.page-footer__section
  +mobile
    transition:     max-height 0.5s ease
    max-height: 3.5em
    overflow: hidden
    position: relative
    background-color: $pageFooterBackgroundColor

.page-footer__section--expanded
  max-height: 1000px
  .page-footer__links-column-expansion-link-expand-text
    display: none
  .page-footer__links-column-expansion-link-collapse-text
    display: block

.page-footer__section-subscribe
  +mobile
    max-height: 1000px

.page-footer__heading
  color: $white
  text-transform: uppercase
  font-weight: bold
  line-height:    1.2em
  margin-bottom:  20px
  +mobile
    margin-bottom: 0
    line-height:   3.5em

.page-footer__links-column-expansion-link
  display: none
  +mobile
    height: 3.5em
    width: 3.5em
    display: block
    position: absolute
    top: 0
    right: 0
    line-height: 3.5em
    color: $newSecondaryTextColor
    &:visited, &:active, &:focus
      color: $newSecondaryTextColor
    &:hover
      color: $white

.page-footer__links-column-expansion-link-collapse-text
  display: none

.page-footer__links-column-link-icon
  margin-left:    5px
  vertical-align: middle

.page-footer__item
  margin: 6px 0px
  line-height: 18px
  &:last-child
    margin-bottom: 0px
  +mobile
    margin: 24px 0px
    &:nth-child(3)
      margin-top: 12px !important
    &:last-child
      margin-bottom: 27px

.page-footer__link
  color:          $newSecondaryTextColor
  white-space: normal
  line-height: 18px
  &:hover, &:active, &:focus
    color: $white
    text-decoration: underline

// Tuts+ logo

.page-footer__tuts-brand
  position: relative
  color:      $headingTextSecondaryColor
  +non-mobile
    margin-bottom: 40px
    float: left
  +mobile
    margin-bottom: 20px

.page-footer__tuts-logo-image
  margin: 7px 0 16px 0
  vertical-align: middle
  width:  auto
  height: 24px

.page-footer__tuts-brand-text
  font-size: 12px
  +non-mobile
    display: block
  +tablet
    display: block
    margin-left: 0
    margin-top: -10px
  +mobile
    display: block
    margin-top: -10px

// Tutorial and Video Course counts

.page-footer__counts
  +non-mobile
    margin-top: 8px
  +mobile-and-tablet
    margin-bottom: 20px

.page-footer__count-item
  display: block
  width: 80px
  float: left
  &:first-child
    margin-left: 0
  +mobile-and-tablet
    display: inline-block
    float: none

.page-footer__count-label
  color: $grey500
  display: block

.page-footer__count-value
  color: $white
  display: block
  font-weight: 500
  font-size: 18px

// Follow Us

.page-footer__social-link
  width:          24px
  height:         24px
  display:        inline-block
  vertical-align: middle

// RSS Digests

.page-footer__rss
  float: left
  width: $four-columns-wide

.page-footer__envato_teaser
  margin-top: 10px
  +mobile
    width: 50%

.page-footer__envato-product
  overflow: hidden
  margin-bottom: 10px
  color: $grey500
  display: inline-block
  background-color: $grey700
  padding: 20px
  position: relative
  &:last-child
    float: right
  +wide
    width: $six-columns-wide
  +desktop
    width: $five-columns-wide - math.div($one-column-wide, 2)
  +tablet
    width: $six-columns-wide
  +mobile
    width: 180px
    &:last-child
      float: none

.page-footer__envato-product-image
  width: 140px
  margin-right: $gutter-width
  float: left

.page-footer__envato-product-description
  margin-left: 140px + $gutter-width
  +mobile
    margin: 122px 0 0 0
    word-wrap: break-word
    font-size: 14px

.page-footer__envato-product-description-text
  +mobile
    display: none

.page-footer__envato-product-link
  margin-top: 5px
  display: block
  color: $white
  position: absolute
  bottom: 20px
  +mobile
    position: initial
    white-space: normal
    text-align: left

// Copyright and envato logo

.page-footer__envato-bar
  background: $pageFooterBackgroundColor

.page-footer__envato-bar-content
  color: $newSecondaryTextColor
  border-top: 1px solid #333333
  +container
  +non-mobile
    padding-top: 25px
    padding-bottom: 25px
  +mobile
    padding: $mobileContentPadding
  overflow: hidden
  position: relative

.page-footer__envato-bar-content-left-column
  +wide
    float: left
    width: $seven-columns-wide
  +desktop
    float: left
    width: $six-columns-wide

.page-footer__envato-bar-content-right-column
  +wide
    float: right
    width: $four-columns-wide
    padding-top: 20px
  +desktop
    float: right
    width: $four-columns-wide
    padding-top: 20px

  +mobile-and-tablet
    float: left
    width: 100%

.dev-info
  float: right

.page-footer__envato-bar-links
  margin: 0
  padding: 0
  list-style-type: none
  line-height: 40px

.page-footer__envato-bar-link-item
  display: inline
  margin-right: 10px

.page-footer__envato-bar-link
  font-size: 14px
  line-height: 40px
  color: $white
  &:hover, &:active, &:focus
    color: $white
    text-decoration: underline


.page-footer__copyright
  display:        block
  float:          left
  font-size:      12px
  line-height:    20px
  +mobile-and-tablet
    float: none
    text-align: left
    line-height: 2em
    margin-bottom: 10px

.page-footer__follow-us
  margin: 0
  text-align: right
  font-size: 12px
  list-style: none

  +mobile-and-tablet
    float: left
    padding: 0

.page-footer__follow-us-element
  display: inline-block
  margin-right: 10px
  +mobile-and-tablet
    &:first-child
      display: inline-block
      line-height: 2.5em
      margin-left: 0

.sticky-footer-ad
  height: 90px
  position: fixed
  z-index: 10
  width: 100vw
  bottom: 0
