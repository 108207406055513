@import '../variables'

.tuts-toast-banner__modal
  z-index: 2147483647
  display: none !important
  position: fixed
  bottom: 40px
  right: 40px

  &.cta-toast--open
    display: unset !important
    +mobile
      display: none !important

  .tuts-toast-banner__wrapper
    background: $white
    border: 1px solid #523ee8
    color: $grey700
    min-width: 500px
    max-width: 600px
    border-radius: 16px
    padding: 30px
    box-shadow: 10px 10px 30px rgb(0 0 0 / 20%)

    a
      color: inherit !important
      text-decoration: underline

    .elements-toast-banner__logo
      height: 32px
      margin-bottom: 16px

    .tuts-toast-banner__copy
      text-decoration: none
      color: $white
      line-height: 1.5
      display: flex
      flex-direction: column
      align-items: flex-start
      margin-bottom: 12px

    .tuts-toast-banner__copy-large
      display: block
      font-size: 24px
      color: $grey1000
      font-weight: 600
      margin-bottom: 12px
      font-style: normal
      font-weight: 600
      line-height: 100%

    .tuts-toast-banner__copy-small
      display: block
      color: $grey1000
      font-size: 16px
      font-style: normal
      font-weight: 400
      line-height: 150%

  &.tuts-toast-banner__modal-with-cta-image
    width: 100vw
    height: 100vh
    top: 0px
    left: 0px
    bottom: unset !important
    right: unset !important
    background: rgba(25, 25, 25, .70)

    .tuts-toast-banner__wrapper
      border: none !important
      padding: 40px
      max-width: 700px
      position: fixed
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)

      .tuts-toast-banner__button
        width: fit-content !important

      .elements-toast-banner__logo
        margin-bottom: 24px

      .tuts-toast-banner__copy
        margin-bottom: 24px

      .tuts-toast-banner__copy-large
        margin-bottom: 24px

.tuts-toast-banner__button
  font-size: 16px
  font-style: normal
  font-weight: 600
  line-height: 150%
  display: flex
  height: 48px
  min-height: 48px
  padding: 8px 24px
  justify-content: center
  align-items: center
  gap: 8px
  background: #9CEE69 !important
  color: #1A4200 !important
  font-weight: 600
  border-radius: 4px
  text-decoration: none !important

.tuts-toast-banner__close-button
  position: absolute
  right: 40px
  top: 40px
  cursor: pointer
  z-index: 12
  background: transparent
  width: 12px
  height: 12px
  border: 0
  transition: opacity 0.1s
  padding: 0px

.tuts-toast-banner__close-button path
  fill: #000

.tuts-toast-banner__cta-image
  margin-bottom: 16px
  width: 620px
  height: 300px
