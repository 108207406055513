@import 'variables'
@import 'mixins/responsive'
@import 'mixins/h1'

*
  box-sizing: border-box

body
  font:                   normal #{$base-font-size}/#{$base-line-height} $bodyFont
  background:             $grey25
  color:                  $grey700
  text-rendering:         optimizeLegibility
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  margin: 0
  padding: 0
  // Prevent iOS from making the fonts bigger in landscape mode x_x
  -webkit-text-size-adjust: none
  overflow-x: hidden


h2, h3, h4, h5, h6
  line-height: 1em
  *
    font-weight: inherit

a
  color: $primaryBlueColor
  text-decoration: none
  border: none

  &:hover, &:active, &:focus
    color: darken($primaryBlueColor, 5%)

  img
    border: none

i[class^=icon-]
  font-size: 16px

h1
  @include h1-style
  *
    font-weight: inherit

figure
  margin: 0
  padding: 0

header, footer, main, section, article
  display: block

fieldset
  border: none
  margin: 0
  padding: 0

input:disabled
  background-color: $grey50

ol.lower-alpha
  list-style-type: lower-alpha

ol.decimal
  list-style-type: decimal

audio, video
  max-width: 100%

// Prevents lazyload img without src to appear
img:not([src])
  visibility: hidden

.spinner
  color: $envatoGreen
  margin: 100px auto 80px
  font-size: 54px
  -webkit-animation: fa-spin 1s infinite linear
  animation: fa-spin 1s infinite linear


// Common layout styles

.clear
  clear: both
  overflow: hidden

.page-body
  margin:     0
  padding:    0
  width: 100%
  min-height: 100vh
  display: flex
  flex-direction: column

.page-content
  position:   relative
  width:      100%
  background: $white
  background-size: contain
  background-repeat: no-repeat
  height: auto
  flex-grow: 1
  display: flex
  flex-direction: column
  align-items: center
  +topic-colors
    border-top: 10px $topic-color solid

.content
  +container
  width: 100%
  display:          block
  background-color: transparent
  +non-mobile
    margin: 48px auto
  +mobile
    padding: 0 $mobileContentPadding
    margin-left: 24px
    margin-right: 24px

// Sticky footer styles
//
// http://pixelsvsbytes.com/blog/2011/09/sticky-css-footers-the-flexible-way/
html
  height: 100%

// Reusable layout elements

.layout__center-column
  margin: 0 auto
  max-width: $nine-columns-wide

.layout__content-full-width
  width: 100%

iframe[name='google_conversion_frame']
  height: 0 !important
  width: 0 !important
  line-height: 0 !important
  font-size: 0 !important
  margin-top: -13px
  float: left

.layout__content-with-sidebar
  +wide
    width: $nine-columns-wide
    float: left
  +desktop
    width: $six-columns-wide
    float: left

.layout__offset-content-with-sidebar
  position: relative
  +wide
    width: $eight-columns-wide
    float: left
    margin-left: $column-width + $gutter-width
  +desktop
    width: $six-columns-wide
    float: left

.layout__offset-content-with-sidebar--post
  +wide
    width: $seven-columns-wide
    margin-left: $two-columns-wide

.layout__sidebar
  +wide
    width: $three-columns-wide
    float: right
  +desktop
    width: $three-columns-wide
    float: right

.layout__sidebar-primary
  +tablet
    width: $three-columns-wide
    float: left
    margin-right: $gutter-width

.layout__sidebar-secondary
  +tablet
    width: $three-columns-wide
    float: right

.layout__navbar
  +wide
    float: left
    width: $two-columns-wide
    margin-right: $gutter-width
  +desktop
    float: left
    width: $two-columns-wide
    margin-right: $gutter-width

.layout__content-with-navbar
  +wide
    width: $ten-columns-wide
    float: right
  +desktop
    width: $seven-columns-wide
    float: right

.fa-button
  webkit-appearance: button
  background-color: transparent
  cursor: pointer
  font-size: 100%
  line-height: inherit
  margin: 0
  padding: 0
  border: none
